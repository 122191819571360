import * as React from "react";
import Layout from "../../components/layout/Layout.component";
import Header from "../../components/header/Header.component";
import Section from "../../components/layout/section/Section.component";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as style from "./Projects.style.module.scss";

const Projects = () => {
  const page = {
    introduction: "Projects",
    header: "Projects",
    description:
      "A page with my projects. Both commercial and private projects are included here. I am constantly trying to add something new and develop old projects.",
  };
  const projectsList = [
    {
      id: 8,
      title: "AB inwestor",
      link: "ab-inwestor",
      description: "#website #commercial-project",
    },
    {
      id: 9,
      title: "Wojciech Koszczyński",
      link: "wojciech-koszczynski",
      description: "#website #commercial-project",
    },
    {
      id: 10,
      title: "Condensa heated pump",
      link: "condensa-pump",
      description: "#website #commercial-project",
    },
    {
      id: 11,
      title: "Restaurant moje miasto",
      link: "moje-miasto",
      description: "#website #commercial-project",
    },
    {
      id: 12,
      title: "Zielone drzwi",
      link: "zielone-drzwi",
      description: "#website #commercial-project",
    },
    {
      id: 13,
      title: "InvestProtect",
      link: "investprotect",
      description: "#website #commercial-project",
    },
    {
      id: 14,
      title: "Upadlość prawnik",
      link: "upadlosc-prawnik",
      description: "#website #commercial-project",
    },
    {
      id: 15,
      title: "Wypożyczalnie condensa",
      link: "condensa-rental",
      description: "#website #commercial-project",
    },
    {
      id: 16,
      title: "SemUp",
      link: "semup",
      description: "#website #commercial-project",
    },
    {
      id: 17,
      title: "Advocate",
      link: "advocate",
      description: "#website #commercial-project",
    },
    {
      id: 18,
      title: "Baltic silver",
      link: "baltic-silver",
      description: "#website #commercial-project",
    },
    {
      id: 19,
      title: "Achilles",
      link: "achilles",
      description: "#website #commercial-project",
    },
    {
      id: 20,
      title: "Cheap Sites",
      link: "cheap-sites",
      description: "#website #commercial-project",
    },
    {
      id: 1,
      title: "Pizzeria luna",
      link: "#",
      description: "#github #website #commercial-project",
    },
    {
      id: 2,
      title: "News wesite",
      link: "#",
      description: "#github #website",
    },
    {
      id: 3,
      title: "Package css",
      link: "#",
      description: "#github #css",
    },
    {
      id: 4,
      title: "Status SSL",
      link: "#",
      description: "#github #website",
    },
    {
      id: 5,
      title: "Plugin 360",
      link: "#",
      description: "#github #js",
    },
    {
      id: 6,
      title: "css framework",
      link: "#",
      description: "#github #css",
    },
    {
      id: 7,
      title: "my website",
      link: "#",
      description: "#github #website",
    },
  ];
  return (
    <Layout pageTitle="Home">
      <Header page={page} />
      <Section>
        <div className={style.card_list}>
          {projectsList.map((project) => (
            <Link key={project.id} to={project.link} className={style.card}>
              <article>
                <StaticImage
                  src="../../images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
                  alt="alt"
                  as="div"
                  className={style.article_image}
                />
                <div className={style.overlayer}>
                  <h2>{project.title}</h2>
                  <p>{project.description}</p>
                </div>
              </article>
            </Link>
          ))}
        </div>
      </Section>
    </Layout>
  );
};
export default Projects;

export function Head() {
  return <title>Bartłomiej Nowak - frontend developer | Projects</title>;
}
